/* eslint-disable camelcase */

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getFirebaseAuthToken } from '../../utils/api'
import { useGetuserDetailsQuery } from '../api'
import { client } from './AxiosClient'
import { KEYS } from './keys'
import { doErrorCheck, doSuccessCheck } from './utils'

/**
 * Get email templates.
 * @returns
 */
export const useGetEmailTemplatesQuery = () => {
  // const queryClient = useQueryClient()
  const getUser = useGetuserDetailsQuery()
  return useQuery(
    KEYS.VENDOR.COMPANIES_EMAIL_TEMPLATES(),
    async () => client.get('/api/v1/tenants/settings/emailTemplates', { headers: { ...await getFirebaseAuthToken() } })
      .then(doSuccessCheck)
      .catch(doErrorCheck),
    {
      retry: 0,
      cacheTime: 60 * 1000,
      staleTime: 60 * 1000,
      enabled: getUser.isSuccess
    }
  )
}

/**
 * Puts an email template.
 * @returns
 */
export const usePutEmailTemplatesMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(async (emailTemplates) => {
    return client.put('/api/v1/tenants/settings/emailTemplates', emailTemplates, { headers: { ...await getFirebaseAuthToken() } })
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.VENDOR.COMPANIES_EMAIL_TEMPLATES())
    }
  }
  )
}
