import MUIDataTable from 'mui-datatables'
import React from 'react'
import { Alert, Badge, Button, Col, Form, Row } from 'react-bootstrap'
import { useGetEmailTemplatesQuery, usePutEmailTemplatesMutation } from '../../../components/api/EmailNotificationsClientApi'
import { CreateSelect, CreateTextArea } from '../../../components/Form'
import Spinner from '../../../components/spinner'
import {
  useGetEmailNotificaitonConfigQuery,
  useUpdateEmailConfigurations
} from '../../../utils/api'
import EmailPreview from './EmailPreview'

const EmailNotification = (props) => {
  const [emailTemplatesForm, setEmailTemplatesForm] = React.useState({
    email_notification_template: '',
    subject: '',
    body: '',
    signOff: '',
    buttonContent: ''
  })

  const getEmailNotificationConfig = useGetEmailNotificaitonConfigQuery()
  const updateEmailNotificationConfig = useUpdateEmailConfigurations()
  const getEmailTemplates = useGetEmailTemplatesQuery()
  const putEmailTemplatesMutation = usePutEmailTemplatesMutation()
  const { data = {} } = getEmailNotificationConfig
  console.log('emailNotifications', { data })
  const emailNotifications = Object.entries(data).map(([key, value]) => ({ ...value, key })).sort((a, b) => a.notification.localeCompare(b.notification))

  const saveCurrentTemplate = () => {
    const { email_notification_template: key, ...template } = emailTemplatesForm
    putEmailTemplatesMutation.mutate({ [key]: template })
  }

  //
  // intialise selected rows base on 'selected' attribute
  //
  const selectedRows =
    getEmailNotificationConfig.isSuccess &&
    emailNotifications
      .filter((data) => data.selected === true)
      .map((data, index) =>
        emailNotifications.indexOf(data)
      )

  /**
   *
   * @param {string} input - the fieldname
   * @returns
   */

  // text areas components returns value directly. so no need to get 'e.target.value'.
  const handleTextArea = (input) => (e) => {
    setEmailTemplatesForm((ps) => ({ ...ps, [input]: e }))
  }
  //
  // handle normal inputs
  // for the templates dropbox. it will set content for all other fields
  //
  const handleInputs = (input) => (e) => {
    setEmailTemplatesForm((ps) => ({ ...ps, [input]: e.target.value }))
    if (
      input === 'email_notification_template' &&
      getEmailTemplates.isSuccess
    ) {
      const selectedTemplate = getEmailTemplates.data[e.target.value]
      setEmailTemplatesForm((ps) => ({
        ...ps,
        subject: selectedTemplate.subject,
        body: selectedTemplate.body,
        signOff: selectedTemplate.signOff,
        buttonContent: selectedTemplate.buttonContent
      }))
    }
  }

  // saves the email notifications...
  // TODO move to api
  const submitConfigrationChanges = (toChangeIndex) => {
    const configurationKey = emailNotifications[toChangeIndex].key
    const selectedStatus = emailNotifications[toChangeIndex].selected
    updateEmailNotificationConfig.mutate({
      configurationKey: configurationKey,
      selectedStatus: selectedStatus
    })
  }

  return (
    <>
      <div
        className="px-1 px-md-5 d-flex flex-column flex-grow-1 my-4"
        id="company-branding-custom"
      >
        <>
          <Row>
            <Col xs={5} className="pr-4 d-flex flex-column flex-grow-1 mb-1">
              <div className="d-flex align-items-center justify-content-between">
                <span className="mb-3 inter-font-content-title">
                  Configurations
                </span>
              </div>
              <p className="mb-0">
                {' '}
                Manage marketplace and account notification preferences by
                selecting the checkbox.
              </p>
            </Col>
          </Row>
          <Row>
          {getEmailNotificationConfig.isLoading &&
            <div className="d-flex justify-content-center mx-auto">
            <Spinner />
            </div>}
            {getEmailNotificationConfig.isSuccess && (
              <MUIDataTable
                className="col-12"
                data={emailNotifications}
                columns={[
                  { label: 'Notification', name: 'notification' },
                  { label: 'Type', name: 'type' },
                  {
                    lable: 'Received By',
                    name: 'receivedBy',
                    options: {
                      customBodyRender: (role, meta) => {
                        return (
                          <>
                            {role === 'Seller'
                              ? (
                              <Badge pill variant="outline-success">
                                Seller
                              </Badge>
                                )
                              : role === 'Buyer'
                                ? (
                              <Badge pill variant="outline-info">
                                Buyer
                              </Badge>
                                  )
                                : (
                              <div>
                                <Badge pill variant="outline-success">
                                  Seller{' '}
                                </Badge>{' '}
                                <Badge pill variant="outline-info">
                                  Buyer
                                </Badge>
                              </div>
                                  )}
                          </>
                        )
                      }
                    }
                  },
                  {
                    label: ' ',
                    name: 'required',
                    options: {
                      viewColumns: false,
                      display: false
                    }
                  }
                ]}
                options={{
                  selectableRows: 'multiple',
                  pagination: true,
                  fixedHeader: true,
                  jumpToPage: false,
                  print: false,
                  selectToolbarPlacement: 'none',
                  selectableRowsHeader: false,
                  customToolbarSelect: () => {},
                  onRowSelectionChange: (
                    currentRowsSelected,
                    allRowsSelected,
                    rowsSelected
                  ) => {
                    if (currentRowsSelected.length > 0) submitConfigrationChanges(currentRowsSelected[0].dataIndex)
                  },
                  isRowSelectable: (dataIndex, selectedRows, data) => {
                    return (
                      emailNotifications[dataIndex]
                        .required === false
                    )
                  },
                  rowsSelected: selectedRows,
                  rowsPerPage: 15,
                  search: true,
                  download: true,
                  sort: true,
                  filter: true,
                  viewColumns: true,
                  elevation: 0,
                  setTableProps: () => ({ size: 'small' })
                }}
              />
            )}
          </Row>
          <Row>
            <Col xs={5} className="pr-4 d-flex flex-column flex-grow-1 my-3">
              <p className="mb-3 inter-font-content-title">
                Customise Email Templates
              </p>
              <p className="mb-0">
                {' '}
                Customise the content in your email notifications.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={5} className=" d-flex flex-column flex-grow-1 mb-3">
              <CreateSelect
                label="Email Notification Templates"
                required
                placeholder="Select a template"
                value={emailTemplatesForm.email_notification_template}
                onChange={handleInputs('email_notification_template')}
                options={[
                  // { value: 'inviteEmail', label: 'Invite Email' }, // Removed by Eugenie
                  {
                    value: 'resetPasswordEmail',
                    label: 'Reset Password'
                  },
                  {
                    value: 'inviteParticipantEmail',
                    label: 'Invited by Seller to join Reflow Hub'
                  },
                  {
                    value: 'inviteMemberEmail',
                    label: 'Invited to join the organisation'
                  },
                  {
                    value: 'passwordChanged',
                    label: 'Password has been changed'
                  },
                  {
                    value: 'newListingOpenEmail',
                    label: 'Listing is open'
                  },
                  {
                    value: 'listingIsEndingSoonEmail',
                    label: 'Listing is ending soon'
                  },
                  {
                    value: 'awardListingEmail',
                    label: 'You\'ve been awarded'
                  },
                  {
                    value: 'unitPriceRequest',
                    label: 'Unit prices requested'
                  },
                  {
                    value: 'awardsNotGiven',
                    label: 'Awards not given'
                  },
                  {
                    value: 'participantPlacedBid',
                    label: 'Participant has placed a bid'
                  },
                  {
                    value: 'unitPriceReceived',
                    label: 'Unit prices received'
                  },
                  {
                    value: 'newSelfSignParticipant',
                    label: 'Account application received'
                  },
                  {
                    value: 'notifySelfSignupOnSuccess',
                    label: 'Account application approved'
                  },
                  {
                    value: 'notifySellSignupOnDeclined',
                    label: 'Account application declined'
                  },
                  {
                    value: 'buyerHasAskedAQuestion',
                    label: 'Participant has asked a question'
                  },
                  {
                    value: 'sellerHasAnsweredAQuestion',
                    label: 'Seller has responded to a question'
                  },
                  {
                    value: 'vendorPostAnnouncement',
                    label: 'Seller has posted an announcement'
                  },
                  {
                    value: 'vendorEditAnnouncement',
                    label: 'Seller has edited an announcement'
                  }
                ].sort((a, b) => a.label.localeCompare(b.label))}
              />
            </Col>
          </Row>
          <Row>
            <hr style={{ width: 100 + '%' }} />
          </Row>
          {getEmailTemplates.isSuccess && (
            <Row >
              <Col xs={5} className="pr-2">
                <Form.Group>
                  <Form.Label>Email Subject and Header</Form.Label>
                  <Form.Control
                    type="text"
                    as="input"
                    autoComplete="new-password"
                    onChange={handleInputs('subject')}
                    value={emailTemplatesForm.subject || ''}
                    placeholder="e.g. List # {list_number} is open"
                  />
                  <br />
                  <Form.Label>Body Copy</Form.Label>
                  <br />
                  <CreateTextArea
                    key={emailTemplatesForm.email_notification_template + 'body'}
                    value={emailTemplatesForm.body || ''}
                    // "<div><!--block-->Hi {first_name},<br><br>A new listing is open for your participation.<br><strong>List</strong> #: {list_number}<br><strong>Sale Type</strong>: {Lot auction/SKU auction/Lot tender/SKU tender}<br><strong>End Time</strong>: {Date and Time}</div>"
                    onChange={handleTextArea('body')}
                  ></CreateTextArea>
                  <Form.Label>Sign Off Message</Form.Label>
                  <br />
                  <CreateTextArea
                    key={emailTemplatesForm.email_notification_template + 'signoff'}
                    value={emailTemplatesForm.signOff || ''}
                    // "<div><!--block-->Happy Bidding!<br><strong>The Reflow Hub Team</strong></div>"
                    onChange={handleTextArea('signOff')}
                  ></CreateTextArea>
                  <div className="email-placeholder-description">
                    <strong> The following placeholders are allowed: </strong>{' '}
                    <br />
                    {[
                      '{InviteLink} - The invitation link',
                      '{FirstName} - First name of user receiving email',
                      '{InviterCompanyName} - Name of company sending invite',
                      '{InviterFirstName} - Name of user sending invite',
                      '{ListingNumber} - Listing number',
                      '{ListingReference} - Listing reference',
                      '{SaleType} - Sale Types e.g. Auction',
                      '{EndDateTime} - Listing end date/time',
                      '{ParticipantCompanyName} - Name of the participant company',
                      '{ParticipantFullName} - Name of the pariticipant user'
                    ].map((s, i) => <div key={i}>{s}</div>)}

                  </div>
                </Form.Group>
                <div style={{ display: 'flex' }}>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    disabled={putEmailTemplatesMutation.isLoading || emailTemplatesForm.email_notification_template === ''}
                    onClick={saveCurrentTemplate}>
                    { putEmailTemplatesMutation.isLoading ? <Spinner /> : 'Save' }
                  </Button>
                </div>
                { putEmailTemplatesMutation.isError && <Alert variant="danger" className="mt-3">{putEmailTemplatesMutation.error.message}</Alert> }
              </Col>
              <Col xs={{ span: 5, offset: 1 }}>
                <EmailPreview
                  subjectHeader={emailTemplatesForm.subject || ''}
                  bodyCopy={emailTemplatesForm.body || ''}
                  signOff={emailTemplatesForm.signOff || ''}
                  buttonContent={emailTemplatesForm.buttonContent || ''}
                ></EmailPreview>
              </Col>
            </Row>
          )}
          {/* ANCHOR Email Section */}
          {process.env.NODE_ENV !== 'production' && (<>
            <pre>emailTemplatesForm={JSON.stringify(emailTemplatesForm, null, 2)}</pre>
            <pre>getEmailTemplates={JSON.stringify(getEmailTemplates, null, 2)}</pre>
            <pre>putEmailTemplatesMutation={JSON.stringify(putEmailTemplatesMutation, null, 2)}</pre>
            </>
          )}
        </>
      </div>
    </>
  )
}

export default EmailNotification
